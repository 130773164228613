import React from 'react'

import {
  SALES_TEAM_MEMBER_INVITES_PATH,
  SALES_TEAM_MEMBER_PRODUCTS_PATH,
  SALES_TEAM_MEMBER_DEALS_PATH,
} from 'constants/routes/salesTeamMember.constants'

import {
  TEAM_MEMBER_OVERVIEW_PATH,
  TEAM_MEMBER_INVITES_PATH,
  TEAM_MEMBER_TRANSFERS_PATH,
  TEAM_MEMBER_PAYOUTS_SCHEDULE,
  TEAM_MEMBER_PAYOUTS_PATH,
  TEAM_MEMBER_PAYOUTS_SETTINGS,
} from 'constants/routes/teamMember.constants'

import {
  PUBLISHER_PAYOUTS_SCHEDULE_PATH,
  PUBLISHER_OVERVIEW_PATH,
  PUBLISHER_ORDERS_PATH,
  PUBLISHER_TRANSFERS_PATH,
  PUBLISHER_AFFILIATE_PROGRAMS_PATH,
  PUBLISHER_AFFILIATE_NODES_PATH,
  PUBLISHER_EMBEDDABLE_ITEMS_PATH,
  PUBLISHER_STATISTICS_OVERVIEW_PATH,
  PUBLISHER_AFFILIATE_MARKETPLACE_PATH,
} from 'constants/routes/publisher.constants'

import {
  PAYER_PRODUCTS_PATH,
  PAYER_ORDERS_PATH,
  PAYER_TRANSFERS_PATH,
  PAYER_COURSE_SESSIONS_PATH,
  PAYER_MEMBERSHIP_SESSIONS_PATH,
  PAYER_PROFILE_PATH,
  PAYER_BANK_ACCOUNTS_PATH,
  PAYER_INVOICES_PATH,
} from 'constants/routes/payer.constants'

import {
  ELO_PUBLISHER_OVERVIEW_PATH,
  ELO_PUBLISHER_PAYOUTS_SCHEDULE_PATH,
} from 'constants/routes/eloPublisher.constants'
import {
  EloHouseIcon,
  EloPackageIcon,
  EloBrowserIcon,
  EloMegaphoneIcon,
  EloCurrencyCircleDollarIcon,
  EloReceiptIcon,
  EloUsersThreeIcon,
  EloChatsCircleIcon,
  EloDeviceMobileIcon,
  EloChartPieIcon,
  EloCardholderIcon,
  EloMoneyIcon,
  EloFilesIcon,
  EloGearIcon,
  EloLinkIcon,
  EloHandshakeIcon,
  EloHandCoinsIcon,
  EloLockIcon,
  EloMarketplaceIcon,
} from '@elo-ui/components/icons/regular'

const mainMenuConfigs = {
  seller: [
    {
      title: I18n.t('react.shared.nav.overview'),
      href: '/cabinet',
      icon: <EloHouseIcon size={16} />,
    },
    {
      id: 'products',
      title: I18n.t('react.shared.nav.products'),
      href: '#',
      icon: <EloPackageIcon size={16} />,
      visibility: ['business', 'powerSeller'],
      children: [
        {
          title: I18n.t('react.cabinet.product.my_products'),
          href: '/cabinet/products',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/products/new'],
        },
        {
          title: I18n.t('react.shared.nav.products_categories'),
          href: '/cabinet/product_groups',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/product_groups/new'],
        },
        {
          title: I18n.t('react.shared.nav.course_themes'),
          href: '/cabinet/course_themes',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/course_themes/new'],
        },
        {
          href: '/cabinet/digitals',
          title: I18n.t('react.shared.nav.file_library'),
          subItems: ['/cabinet/digitals', '/cabinet/covers', '/cabinet/videos'],
          visibility: ['business', 'powerSeller'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.pages'),
      href: '#',
      icon: <EloBrowserIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.shop_themes'),
          href: '/cabinet/shop_themes',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/shop_themes/new'],
        },
        {
          title: I18n.t('react.shared.membership_themes'),
          href: '/cabinet/membership_themes',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/membership_themes/new'],
        },
        {
          title: I18n.t('react.shared.nav.sharing'),
          href: '/cabinet/sharings',
          visibility: ['business', 'powerSeller'],
          key: 'sharings',
        },
      ],
    },
    {
      id: 'marketing',
      title: I18n.t('react.shared.nav.market_sell'),
      href: '#',
      icon: <EloMegaphoneIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.nav.coupons'),
          href: '/cabinet/coupons',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/coupons', '/cabinet/coupons/new'],
        },
        {
          title: I18n.t('react.shared.nav.marketing_tools'),
          href: '/cabinet/embeddable_items',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/embeddable_items/new'],
        },
        {
          title: I18n.t('react.shared.nav.live_shopping_events'),
          href: '/cabinet/live_shopping_events',
          subItems: ['/cabinet/live_shopping_events', '/cabinet/live_shopping_events/new'],
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.content_pages'),
          href: '/cabinet/affiliate_redirections',
          subItems: ['/cabinet/affiliate_redirections', '/cabinet/affiliate_redirections/new'],
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.cabinet.upsell_funnels.title'),
          href: '/cabinet/funnels',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/funnels', '/cabinet/funnel_pages'],
        },
        {
          title: I18n.t('react.shared.nav.order_bumps'),
          href: '/cabinet/order_bumps_templates',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.tracking_codes'),
          href: '/cabinet/tracking_codes',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/tracking_codes/new'],
        },
        {
          title: I18n.t('react.shared.nav.email_automations'),
          href: '/cabinet/email_automations',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/email_automations/new'],
        },
        {
          title: I18n.t('react.shared.nav.webhook_endpoints'),
          href: '/cabinet/webhook_endpoints',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/webhook_endpoints', '/cabinet/webhook_endpoints/new'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.sales_team'),
      href: '#',
      icon: <EloCurrencyCircleDollarIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.nav.sales_team'),
          href: '/cabinet/sales_team',
          fieldToCheck: 'showSalesTeam',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.deals'),
          href: '/cabinet/deals',
          fieldToCheck: 'showTeamTabs',
          optionKeys: ['sales_team'],
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.incentive_plan'),
          href: '/cabinet/incentive_plan',
          fieldToCheck: 'showTeamTabs',
          optionKeys: ['sales_team'],
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.members'),
          href: '/cabinet/sales_team_member_invites',
          fieldToCheck: 'showTeamTabs',
          optionKeys: ['sales_team'],
          visibility: ['business', 'powerSeller'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.payments'),
      href: '#',
      icon: <EloReceiptIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.nav.orders'),
          href: '/cabinet/orders',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.order_rates'),
          href: '/cabinet/order_rates',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.transfers'),
          href: '/cabinet/transfers',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.cabinet.common.invoices_reversals'),
          href: '/cabinet/invoices',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/invoices/new'],
        },
        {
          title: I18n.t('react.cabinet.need_action_transfers.title_new'),
          href: '/cabinet/need_action_transfers',
          visibility: ['business', 'powerSeller'],
          key: 'virtual_iban',
          isMongoPayReady: true,
        },
        {
          title: I18n.t('react.cabinet.partially_payments'),
          href: '/cabinet/viban_payments_tab',
          visibility: ['business', 'powerSeller'],
          isElopageConnectReady: true,
        },
        {
          title: I18n.t('react.shared.nav.dunning'),
          href: '/cabinet/dunning/past_due_orders',
          visibility: ['business'],
          subItems: [
            '/cabinet/dunning/past_due_orders',
            '/cabinet/dunning/open',
            '/cabinet/dunning/resolved',
            '/cabinet/dunning/reports',
          ],
        },
        {
          title: I18n.t('react.shared.nav.dunning'),
          href: '/cabinet/dunning/open',
          visibility: ['powerSeller'],
          subItems: ['/cabinet/dunning/open', '/cabinet/dunning/resolved'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.affiliate'),
      href: '#',
      icon: <EloHandshakeIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.nav.affiliate_programs'),
          href: '/cabinet/affiliate_programs',
          subItems: ['/cabinet/affiliate_programs/new', '/cabinet/affiliate_programs'],
          visibility: ['business', 'powerSeller'],
          key: 'affiliate_programs',
        },
        {
          title: I18n.t('react.shared.nav.affiliate_marketplace'),
          href: '/cabinet/affiliate_marketplace',
          visibility: ['business', 'powerSeller'],
          key: 'affiliate_programs',
          target: '_blank',
          optionKeys: ['affiliate_marketplace'],
        },
        {
          title: I18n.t('react.shared.publisher.publishers'),
          href: '/cabinet/affiliate_nodes',
          subItems: ['/cabinet/affiliate_nodes', '/cabinet/affiliate_nodes/new'],
          visibility: ['business', 'powerSeller'],
          key: 'affiliate_nodes',
        },
        {
          title: I18n.t('shared.common.statistics'),
          href: '/cabinet/affiliate_statistics',
          subItems: ['/cabinet/affiliate_statistics/affiliates_analysis', '/cabinet/affiliate_statistics'],
          visibility: ['business', 'powerSeller'],
          key: 'affiliate_statistics',
        },
        {
          title: I18n.t('react.shared.landing_pages'),
          href: '/cabinet/affiliate_landings',
          visibility: ['business', 'powerSeller'],
          key: 'affiliate_landings',
          subItems: ['/cabinet/affiliate_landings/new'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.customers'),
      href: '#',
      icon: <EloUsersThreeIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.nav.customers'),
          href: '/cabinet/payers',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/contact_form_leads'],
        },
        {
          title: I18n.t('react.shared.nav.courses'),
          href: '/cabinet/courses/overview',
          visibility: ['business', 'powerSeller'],
          subItems: [
            '/cabinet/courses/overview',
            '/cabinet/courses/sessions',
            '/cabinet/courses/lesson_statuses',
            '/cabinet/courses/quiz_attempts',
          ],
        },
        {
          title: I18n.t('react.shared.nav.membership_access'),
          href: '/cabinet/membership_sessions/overview',
          visibility: ['business', 'powerSeller'],
          subItems: [
            '/cabinet/membership_sessions',
            '/cabinet/membership_access/overview',
            '/cabinet/membership_access/details',
            '/cabinet/membership_sessions/access',
          ],
        },
        {
          title: I18n.t('react.shared.nav.payment_tickets'),
          href: '/cabinet/payment_tickets/overview',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/payment_tickets/details', '/cabinet/payment_tickets'],
        },
        {
          title: I18n.t('react.shared.nav.payment_certificates'),
          href: '/cabinet/certificates/overview',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/certificates/details'],
        },
        {
          title: I18n.t('react.shared.nav.license_codes'),
          href: '/cabinet/license_keys/overview',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/license_keys/details'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.communities'),
      href: '/cabinet/communities',
      icon: <EloChatsCircleIcon size={16} />,
      visibility: ['business', 'powerSeller'],
      withLabel: true,
      type: 'community_feature',
    },
    {
      title: I18n.t('react.shared.nav.mobile_app'),
      href: '#',
      icon: <EloDeviceMobileIcon size={16} />,
      visibility: ['business', 'powerSeller'],
      type: 'mobile_app',
      withLabel: true,
      optionKeys: ['mobile_app'],
      children: [
        {
          title: I18n.t('react.shared.nav.mobile_app_instruction'),
          href: '/cabinet/instruction',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.mobile_app_general_settings'),
          href: '/cabinet/mobile_app',
          visibility: ['business', 'powerSeller'],
          optionKeys: ['mobile_app'],
          subItems: [
            '/cabinet/mobile_app#overview',
            '/cabinet/mobile_app#googleAccount',
            '/cabinet/mobile_app#appleAccount',
            '/cabinet/mobile_app#notifications',
            '/cabinet/mobile_app#products',
            '/cabinet/mobile_app#firebase',
            '/cabinet/mobile_app#settings',
          ],
        },
        {
          optionKeys: ['mobile_app'],
          title: I18n.t('react.cabinet.iap_product_step.iap_list'),
          href: '/cabinet/iap_products',
          visibility: ['business', 'powerSeller'],
        },
        {
          optionKeys: ['mobile_app'],
          title: I18n.t('react.shared.nav.notifications'),
          href: '/cabinet/notifications',
          subItems: ['/cabinet/seller_custom_notifications/new'],
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.app_themes'),
          href: '/cabinet/app_themes',
          visibility: ['business', 'powerSeller'],
          key: 'app_themes',
          optionKeys: ['mobile_app'],
          subItems: ['/cabinet/app_themes/new'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.analytics'),
      href: '#',
      icon: <EloChartPieIcon size={16} />,
      visibility: ['business', 'powerSeller'],
      children: [
        {
          title: I18n.t('react.shared.nav.analytics'),
          href: '/cabinet/analysis/traffic',
          visibility: ['business', 'powerSeller'],
          subItems: [
            '/cabinet/analysis/traffic',
            '/cabinet/analysis/payments',
            '/cabinet/analysis/interactions',
            '/cabinet/analysis/orders',
            '/cabinet/analysis/best_sellers',
            '/cabinet/analysis/top_customers',
            '/cabinet/analysis/campaigns',
          ],
        },
        {
          title: I18n.t('react.shared.nav.analytics3'),
          href: '/cabinet/analysis/business',
          visibility: ['business', 'powerSeller'],
          subItems: [
            '/cabinet/analysis/business',
            '/cabinet/analysis/product',
            '/cabinet/analysis/customers',
            '/cabinet/analysis/conversion',
            '/cabinet/analysis/funnels',
            '/cabinet/analysis/bumps',
            '/cabinet/analysis/marketing',
            '/cabinet/analysis/sales_seller',
          ],
        },
      ],
    },
    {
      id: 'templates',
      title: I18n.t('react.shared.nav.checkout_tools'),
      icon: <EloCardholderIcon size={16} />,
      href: '#',
      children: [
        {
          title: I18n.t('react.shared.nav.checkout_page_templates'),
          href: '/cabinet/payment_page_templates',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/payment_page_templates', '/cabinet/payment_page_templates/new'],
        },
        {
          title: I18n.t('react.shared.nav.pricing_plans'),
          href: '/cabinet/pricing_plans',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/pricing_plans/new'],
        },
        {
          title: I18n.t('react.shared.nav.additional_fees'),
          href: '/cabinet/additional_fees',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.cancellation_terms'),
          href: '/cabinet/cancellation_terms/default',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/cancellation_terms/custom', '/cabinet/cancellation_terms/new'],
        },
        {
          title: I18n.t('react.shared.nav.opt_ins'),
          href: '/cabinet/opt_ins',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.custom_field_responses'),
          href: '/cabinet/opt_in_answers',
          visibility: ['business', 'powerSeller'],
        },
        {
          href: '/cabinet/vat_settings',
          title: I18n.t('react.shared.nav.settings_tab.vat_settings'),
          visibility: ['business'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.payouts_cashout'),
      href: '#',
      icon: <EloMoneyIcon size={16} />,
      children: [
        {
          href: '/cabinet/cashouts',
          title: I18n.t('react.shared.nav.cashout'),
          visibility: ['business'],
          fieldToCheck: 'showCashout',
        },
        {
          href: '/cabinet/financial_reports',
          title: I18n.t('react.shared.nav.reports'),
          visibility: ['business'],
          subItems: ['/cabinet/financial_reports', '/cabinet/invoice_summaries'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.payouts_cashout'),
      href: '#',
      icon: <EloMoneyIcon size={16} />,
      children: [
        {
          href: '/cabinet/payouts_balance',
          title: I18n.t('react.shared.nav.payouts_cashout'),
          visibility: ['powerSeller'],
          fieldToCheck: 'showPayout',
          subItems: ['/cabinet/payouts', '/cabinet/payouts_settings'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.logs'),
      href: '/cabinet/logs/emails',
      icon: <EloFilesIcon size={16} />,
      subItems: [
        '/cabinet/logs/contacts',
        '/cabinet/logs/webhooks',
        '/cabinet/logs/tracking',
        '/cabinet/logs/csv',
        '/cabinet/logs/push_notifications',
      ],
    },
    {
      title: I18n.t('react.shared.nav.settings'),
      href: '#',
      icon: <EloGearIcon size={16} />,
      action: 'toggleOwnSidebar',
    },
  ],
  publisher: [
    {
      title: I18n.t('react.shared.nav.overview'),
      href: PUBLISHER_OVERVIEW_PATH,
      icon: <EloHouseIcon size={16} />,
    },
    {
      title: I18n.t('react.shared.nav.affiliate_marketplace'),
      href: PUBLISHER_AFFILIATE_MARKETPLACE_PATH,
      visibility: ['business', 'powerSeller'],
      key: 'affiliate_programs',
      target: '_blank',
      icon: <EloMarketplaceIcon size={16} />,
    },
    {
      title: I18n.t('react.shared.nav.affiliate'),
      href: PUBLISHER_AFFILIATE_PROGRAMS_PATH,
      subItems: [
        PUBLISHER_AFFILIATE_PROGRAMS_PATH,
        PUBLISHER_AFFILIATE_NODES_PATH,
        '/publish/affiliate_invites',
        '/publish/affiliate_invites/new',
      ],
      icon: <EloHandshakeIcon size={16} />,
    },
    {
      id: 'embeddableItems',
      icon: <EloLinkIcon size={16} />,
      title: I18n.t('react.shared.nav.affiliate_links'),
      href: PUBLISHER_EMBEDDABLE_ITEMS_PATH,
    },
    {
      title: I18n.t('react.shared.nav.stats'),
      href: PUBLISHER_STATISTICS_OVERVIEW_PATH,
      subItems: [PUBLISHER_STATISTICS_OVERVIEW_PATH, '/publish/affiliate_statistics/affiliates_analysis'],
      icon: <EloChartPieIcon size={16} />,
    },
    {
      title: I18n.t('react.shared.nav.payments'),
      href: '#',
      icon: <EloReceiptIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.nav.transfers'),
          href: PUBLISHER_TRANSFERS_PATH,
        },
        {
          title: I18n.t('react.shared.nav.orders'),
          href: PUBLISHER_ORDERS_PATH,
        },
      ],
    },

    {
      title: I18n.t('react.shared.nav.payouts'),
      href: PUBLISHER_PAYOUTS_SCHEDULE_PATH,
      icon: <EloMoneyIcon size={16} />,
    },
    {
      title: I18n.t('react.shared.nav.settings'),
      href: '#',
      icon: <EloGearIcon size={16} />,
      action: 'toggleOwnSidebar',
    },
  ],
  elo_publisher: [
    {
      title: I18n.t('react.shared.nav.overview'),
      href: ELO_PUBLISHER_OVERVIEW_PATH,
      icon: <EloHouseIcon size={16} />,
    },
    {
      title: I18n.t('react.shared.nav.payouts'),
      href: ELO_PUBLISHER_PAYOUTS_SCHEDULE_PATH,
      icon: <EloMoneyIcon size={16} />,
    },
    {
      title: I18n.t('react.shared.nav.settings'),
      href: '#',
      icon: <EloGearIcon size={16} />,
      action: 'toggleOwnSidebar',
    },
  ],
  team_member: [
    {
      title: I18n.t('react.shared.nav.team_project'),
      href: '#',
      icon: <EloUsersThreeIcon size={16} />,
      static: true,
      children: [
        {
          title: I18n.t('react.shared.nav.homepage'),
          href: TEAM_MEMBER_OVERVIEW_PATH,
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.team_invites'),
          href: TEAM_MEMBER_INVITES_PATH,
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.team_transfers'),
          href: TEAM_MEMBER_TRANSFERS_PATH,
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.team_payouts'),
          href: TEAM_MEMBER_PAYOUTS_SCHEDULE,
          visibility: ['business', 'powerSeller'],
          subItems: [TEAM_MEMBER_PAYOUTS_PATH, TEAM_MEMBER_PAYOUTS_SETTINGS],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.overview'),
      href: '/cabinet',
      icon: <EloHouseIcon size={16} />,
      key: 'overview',
    },
    {
      id: 'products',
      title: I18n.t('react.shared.nav.products'),
      href: '#',
      icon: <EloPackageIcon size={16} />,
      visibility: ['business', 'powerSeller'],
      children: [
        {
          title: I18n.t('react.cabinet.product.my_products'),
          href: '/cabinet/products',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/products/new'],
          key: 'products',
        },
        {
          title: I18n.t('react.shared.nav.products_categories'),
          href: '/cabinet/product_groups',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/product_groups/new'],
          key: 'product_groups',
        },
        {
          title: I18n.t('react.shared.nav.course_themes'),
          href: '/cabinet/course_themes',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/course_themes/new'],
          key: 'course_themes',
        },
        {
          href: '/cabinet/digitals',
          title: I18n.t('react.shared.nav.file_library'),
          subItems: ['/cabinet/digitals', '/cabinet/covers', '/cabinet/videos'],
          visibility: ['business', 'powerSeller'],
          key: 'digitals',
        },
      ],
    },
    {
      title: I18n.t('react.shared.pages'),
      href: '#',
      icon: <EloBrowserIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.shop_themes'),
          href: '/cabinet/shop_themes',
          visibility: ['business', 'powerSeller'],
          key: 'shop_themes',
          subItems: ['/cabinet/shop_themes/new'],
        },
        {
          title: I18n.t('react.shared.membership_themes'),
          href: '/cabinet/membership_themes',
          visibility: ['business', 'powerSeller'],
          key: 'membership_themes',
          subItems: ['/cabinet/membership_themes/new'],
        },
        {
          title: I18n.t('react.shared.nav.sharing'),
          href: '/cabinet/sharings',
          visibility: ['business', 'powerSeller'],
          key: 'sharings',
        },
      ],
    },
    {
      id: 'marketing',
      title: I18n.t('react.shared.nav.market_sell'),
      href: '#',
      icon: <EloMegaphoneIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.nav.coupons'),
          href: '/cabinet/coupons',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/coupons', '/cabinet/coupons/new'],
          key: 'coupons',
        },
        {
          title: I18n.t('react.shared.nav.marketing_tools'),
          href: '/cabinet/embeddable_items',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/embeddable_items/new'],
          key: 'embeddable_items',
        },
        {
          title: I18n.t('react.shared.nav.live_shopping_events'),
          href: '/cabinet/live_shopping_events',
          subItems: ['/cabinet/live_shopping_events', '/cabinet/live_shopping_events/new'],
          visibility: ['business', 'powerSeller'],
          key: 'live_shopping_events',
        },
        {
          title: I18n.t('react.shared.nav.content_pages'),
          href: '/cabinet/affiliate_redirections',
          subItems: ['/cabinet/affiliate_redirections', '/cabinet/affiliate_redirections/new'],
          visibility: ['business', 'powerSeller'],
          key: 'affiliate_redirections',
        },
        {
          title: I18n.t('react.cabinet.upsell_funnels.title'),
          href: '/cabinet/funnels',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/funnels', '/cabinet/funnel_pages'],
          key: 'funnels',
        },
        {
          title: I18n.t('react.shared.nav.order_bumps'),
          href: '/cabinet/order_bumps_templates',
          visibility: ['business', 'powerSeller'],
          key: 'order_bumps',
        },
        {
          title: I18n.t('react.shared.nav.tracking_codes'),
          href: '/cabinet/tracking_codes',
          visibility: ['business', 'powerSeller'],
          key: 'tracking_codes',
          subItems: ['/cabinet/tracking_codes/new'],
        },
        {
          title: I18n.t('react.shared.nav.email_automations'),
          href: '/cabinet/email_automations',
          visibility: ['business', 'powerSeller'],
          key: 'email_automations',
          subItems: ['/cabinet/email_automations/new'],
        },
        {
          title: I18n.t('react.shared.nav.webhook_endpoints'),
          href: '/cabinet/webhook_endpoints',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/webhook_endpoints', '/cabinet/webhook_endpoints/new'],
          key: 'webhook_endpoints',
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.sales_team'),
      href: '#',
      icon: <EloCurrencyCircleDollarIcon size={16} />,
      key: 'sales_teams',
      children: [
        {
          title: I18n.t('react.shared.nav.sales_team'),
          href: '/cabinet/sales_team',
          key: 'sales_teams',
          fieldToCheck: 'showSalesTeam',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.deals'),
          href: '/cabinet/deals',
          key: 'sales_teams',
          fieldToCheck: 'showTeamTabs',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.incentive_plan'),
          href: '/cabinet/incentive_plan',
          fieldToCheck: 'showTeamTabs',
          visibility: ['business', 'powerSeller'],
          key: 'sales_teams',
        },
        {
          title: I18n.t('react.shared.nav.members'),
          href: '/cabinet/sales_team_member_invites',
          fieldToCheck: 'showTeamTabs',
          visibility: ['business', 'powerSeller'],
          key: 'sales_teams',
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.payments'),
      href: '#',
      icon: <EloReceiptIcon size={16} />,
      useSellerLegalForm: true,
      children: [
        {
          title: I18n.t('react.shared.nav.orders'),
          href: '/cabinet/orders',
          visibility: ['business', 'powerSeller'],
          key: 'orders',
        },
        {
          title: I18n.t('react.shared.nav.order_rates'),
          href: '/cabinet/order_rates',
          visibility: ['business', 'powerSeller'],
          key: 'order_rates',
        },
        {
          title: I18n.t('react.shared.nav.transfers'),
          href: '/cabinet/transfers',
          visibility: ['business', 'powerSeller'],
          key: 'transfers',
        },
        {
          title: I18n.t('react.cabinet.common.invoices_reversals'),
          href: '/cabinet/invoices',
          visibility: ['business', 'powerSeller'],
          key: 'invoices',
          subItems: ['/cabinet/invoices/new'],
        },
        {
          title: I18n.t('react.cabinet.need_action_transfers.title_new'),
          href: '/cabinet/need_action_transfers',
          visibility: ['business', 'powerSeller'],
          key: 'virtual_iban',
          isMongoPayReady: true,
        },
        {
          title: I18n.t('react.cabinet.partially_payments'),
          href: '/cabinet/viban_payments_tab',
          visibility: ['business', 'powerSeller'],
          isElopageConnectReady: true,
        },
        {
          title: I18n.t('react.shared.nav.dunning'),
          href: '/cabinet/dunning/past_due_orders',
          visibility: ['business'],
          subItems: [
            '/cabinet/dunning/past_due_orders',
            '/cabinet/dunning/open',
            '/cabinet/dunning/resolved',
            '/cabinet/dunning/reports',
          ],
          key: 'dunning',
          useSellerLegalForm: true,
        },
        {
          title: I18n.t('react.shared.nav.dunning'),
          href: '/cabinet/dunning/open',
          visibility: ['powerSeller'],
          subItems: ['/cabinet/dunning/open', '/cabinet/dunning/resolved'],
          key: 'dunning',
          useSellerLegalForm: true,
        },
      ],
    },
    {
      title: I18n.t('react.shared.affiliate'),
      href: '#',
      icon: <EloHandshakeIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.nav.affiliate_programs'),
          href: '/cabinet/affiliate_programs',
          subItems: ['/cabinet/affiliate_programs/new', '/cabinet/affiliate_programs'],
          visibility: ['business', 'powerSeller'],
          key: 'affiliate_programs',
        },
        {
          title: I18n.t('react.shared.publisher.publishers'),
          href: '/cabinet/affiliate_nodes',
          subItems: ['/cabinet/affiliate_nodes', '/cabinet/affiliate_nodes/new'],
          visibility: ['business', 'powerSeller'],
          key: 'affiliate_programs',
        },
        {
          title: I18n.t('shared.common.statistics'),
          href: '/cabinet/affiliate_statistics',
          subItems: ['/cabinet/affiliate_statistics/affiliates_analysis', '/cabinet/affiliate_statistics'],
          visibility: ['business', 'powerSeller'],
          key: 'affiliate_programs',
        },
        {
          title: I18n.t('react.shared.landing_pages'),
          href: '/cabinet/affiliate_landings',
          visibility: ['business', 'powerSeller'],
          key: 'affiliate_programs',
          subItems: ['/cabinet/affiliate_landings/new'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.customers'),
      href: '#',
      icon: <EloUsersThreeIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.nav.customers'),
          href: '/cabinet/payers',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/contact_form_leads'],
          key: 'payers',
        },
        {
          title: I18n.t('react.shared.nav.courses'),
          href: '/cabinet/courses/overview',
          visibility: ['business', 'powerSeller'],
          subItems: [
            '/cabinet/courses/overview',
            '/cabinet/courses/sessions',
            '/cabinet/courses/lesson_statuses',
            '/cabinet/courses/quiz_attempts',
          ],
          key: 'courses',
        },
        {
          title: I18n.t('react.shared.nav.membership_access'),
          href: '/cabinet/membership_sessions/overview',
          visibility: ['business', 'powerSeller'],
          key: 'membership_sessions',
          subItems: ['/cabinet/membership_sessions'],
        },
        {
          title: I18n.t('react.shared.nav.payment_tickets'),
          href: '/cabinet/payment_tickets/overview',
          visibility: ['business', 'powerSeller'],
          key: 'tickets',
          subItems: ['/cabinet/payment_tickets/details'],
        },
        {
          title: I18n.t('react.shared.nav.payment_certificates'),
          href: '/cabinet/certificates/overview',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/certificates/details'],
          key: 'certificates',
        },
        {
          title: I18n.t('react.shared.nav.license_codes'),
          href: '/cabinet/license_keys/overview',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/license_keys/details'],
          key: 'license_keys',
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.mobile_app'),
      href: '#',
      icon: <EloDeviceMobileIcon size={16} />,
      visibility: ['business', 'powerSeller'],
      type: 'mobile_app',
      withLabel: true,
      children: [
        {
          title: I18n.t('react.shared.nav.mobile_app_instruction'),
          href: '/cabinet/instruction',
          key: 'mobile_app',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.mobile_app_general_settings'),
          href: '/cabinet/mobile_app',
          key: 'mobile_app',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.cabinet.iap_product_step.iap_list'),
          href: '/cabinet/iap_products',
          key: 'mobile_app',
          visibility: ['business', 'powerSeller'],
        },
        {
          title: I18n.t('react.shared.nav.notifications'),
          href: '/cabinet/notifications',
          subItems: ['/cabinet/seller_custom_notifications/new'],
          visibility: ['business', 'powerSeller'],
          key: 'mobile_app',
        },
        {
          title: I18n.t('react.shared.app_themes'),
          href: '/cabinet/app_themes',
          visibility: ['business', 'powerSeller'],
          key: 'app_themes',
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.analytics'),
      href: '/cabinet/analysis/traffic',
      icon: <EloChartPieIcon size={16} />,
      visibility: ['business', 'powerSeller'],
      subItems: [
        '/cabinet/analysis/traffic',
        '/cabinet/analysis/payments',
        '/cabinet/analysis/interactions',
        '/cabinet/analysis/orders',
        '/cabinet/analysis/best_sellers',
        '/cabinet/analysis/top_customers',
        '/cabinet/analysis/campaigns',
      ],
      key: 'analysis',
      children: [
        {
          title: I18n.t('react.shared.nav.analytics'),
          href: '/cabinet/analysis/traffic',
          visibility: ['business', 'powerSeller'],
          key: 'analysis',
          subItems: [
            '/cabinet/analysis/traffic',
            '/cabinet/analysis/payments',
            '/cabinet/analysis/interactions',
            '/cabinet/analysis/orders',
            '/cabinet/analysis/best_sellers',
            '/cabinet/analysis/top_customers',
            '/cabinet/analysis/campaigns',
          ],
        },
        {
          title: I18n.t('react.shared.nav.analytics3'),
          href: '/cabinet/analysis/business',
          visibility: ['business', 'powerSeller'],
          key: [
            'metabase_analytics_bumps',
            'metabase_analytics_funnels',
            'metabase_analytics_marketing',
            'metabase_analytics_product',
            'metabase_analytics_customers',
            'metabase_analytics_conversion',
            'metabase_analytics_business',
          ],
          subItems: [
            '/cabinet/analysis/business',
            '/cabinet/analysis/product',
            '/cabinet/analysis/customers',
            '/cabinet/analysis/conversion',
            '/cabinet/analysis/funnels',
            '/cabinet/analysis/bumps',
            '/cabinet/analysis/marketing',
            '/cabinet/analysis/sales_seller',
          ],
        },
      ],
    },
    {
      id: 'templates',
      title: I18n.t('react.shared.nav.checkout_tools'),
      icon: <EloCardholderIcon size={16} />,
      href: '#',
      children: [
        {
          title: I18n.t('react.shared.nav.checkout_page_templates'),
          href: '/cabinet/payment_page_templates',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/payment_page_templates', '/cabinet/payment_page_templates/new'],
          key: 'payment_page_templates',
        },
        {
          title: I18n.t('react.shared.nav.pricing_plans'),
          href: '/cabinet/pricing_plans',
          visibility: ['business', 'powerSeller'],
          key: 'pricing_plans',
          subItems: ['/cabinet/pricing_plans/new'],
        },
        {
          title: I18n.t('react.shared.nav.additional_fees'),
          href: '/cabinet/additional_fees',
          visibility: ['business', 'powerSeller'],
          key: 'additional_fees',
        },
        {
          title: I18n.t('react.shared.nav.cancellation_terms'),
          href: '/cabinet/cancellation_terms/default',
          visibility: ['business', 'powerSeller'],
          subItems: ['/cabinet/cancellation_terms/custom', '/cabinet/cancellation_terms/new'],
          key: 'cancellation_terms',
        },
        {
          title: I18n.t('react.shared.nav.opt_ins'),
          href: '/cabinet/opt_ins',
          visibility: ['business', 'powerSeller'],
          key: 'opt_ins',
        },
        {
          title: I18n.t('react.shared.nav.custom_field_responses'),
          href: '/cabinet/opt_in_answers',
          visibility: ['business', 'powerSeller'],
          key: 'opt_in_answers',
        },
        {
          href: '/cabinet/vat_settings',
          title: I18n.t('react.shared.nav.settings_tab.vat_settings'),
          visibility: ['business', 'powerSeller'],
          key: 'vat_settings',
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.payouts'),
      href: '#',
      icon: <EloMoneyIcon size={16} />,
      children: [
        {
          href: '/cabinet/cashouts',
          title: I18n.t('react.shared.nav.cashout'),
          visibility: ['business', 'powerSeller'],
          key: 'cashouts',
        },
        {
          href: '/cabinet/payouts_balance',
          title: I18n.t('react.shared.nav.payouts'),
          visibility: ['powerSeller'],
          fieldToCheck: 'showPayout',
          subItems: ['/cabinet/payouts', '/cabinet/payouts_settings'],
          key: 'payouts_balance',
        },
        {
          href: '/cabinet/financial_reports',
          title: I18n.t('react.shared.nav.reports'),
          visibility: ['business'],
          subItems: ['/cabinet/financial_reports', '/cabinet/invoice_summaries'],
          key: 'financial_reports',
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.logs'),
      href: '/cabinet/logs/emails',
      icon: <EloFilesIcon size={16} />,
      subItems: [
        '/cabinet/logs/contacts',
        '/cabinet/logs/webhooks',
        '/cabinet/logs/tracking',
        '/cabinet/logs/csv',
        '/cabinet/logs/push_notifications',
      ],
      key: 'logs',
    },
    {
      title: I18n.t('react.shared.nav.settings'),
      href: '#',
      icon: <EloGearIcon size={16} />,
      action: 'toggleOwnSidebar',
      static: true,
    },
  ],
  sales_team_member: [
    {
      title: I18n.t('react.shared.nav.team_project'),
      href: '#',
      icon: <EloUsersThreeIcon size={16} />,
      static: true,
      children: [
        {
          title: I18n.t('react.shared.nav.team_invites'),
          href: SALES_TEAM_MEMBER_INVITES_PATH,
          visibility: ['business', 'powerSeller'],
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.overview'),
      icon: 'far fa-home-alt',
      href: '/sales_team_member/overview',
    },
    {
      title: I18n.t('react.shared.nav.products'),
      href: SALES_TEAM_MEMBER_PRODUCTS_PATH,
      icon: <EloPackageIcon size={16} />,
      fieldToCheck: 'additionalItemOnSalesMemberMenu',
    },
    {
      title: I18n.t('react.shared.nav.my_deals'),
      href: SALES_TEAM_MEMBER_DEALS_PATH,
      icon: <EloHandCoinsIcon size={16} />,
      fieldToCheck: 'additionalItemOnSalesMemberMenu',
    },
    {
      title: I18n.t('react.shared.nav.settings'),
      href: '#',
      icon: <EloGearIcon size={16} />,
      action: 'toggleOwnSidebar',
    },
  ],
  payer: [
    {
      id: 'products',
      title: I18n.t('react.shared.nav.products'),
      href: PAYER_PRODUCTS_PATH,
      icon: <EloPackageIcon size={16} />,
      key: 'products',
    },
    {
      title: I18n.t('react.shared.nav.payments'),
      href: '#',
      icon: <EloReceiptIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.nav.orders'),
          href: PAYER_ORDERS_PATH,
        },
        {
          title: I18n.t('react.shared.nav.transfers'),
          href: PAYER_TRANSFERS_PATH,
        },
        {
          title: I18n.t('react.cabinet.common.invoices_reversals'),
          href: PAYER_INVOICES_PATH,
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.access'),
      href: '#',
      icon: <EloLockIcon size={16} />,
      children: [
        {
          title: I18n.t('react.shared.nav.courses'),
          href: PAYER_COURSE_SESSIONS_PATH,
        },
        {
          title: I18n.t('react.shared.nav.membership_access'),
          href: PAYER_MEMBERSHIP_SESSIONS_PATH,
        },
      ],
    },
    {
      title: I18n.t('react.shared.nav.settings'),
      href: '#',
      icon: <EloGearIcon size={16} />,
      children: [
        {
          href: PAYER_PROFILE_PATH,
          title: I18n.t('react.shared.nav.profile'),
        },
        {
          href: PAYER_BANK_ACCOUNTS_PATH,
          title: I18n.t('react.shared.nav.settings_tab.bank_accounts'),
        },
      ],
    },
  ],
}

export default mainMenuConfigs
